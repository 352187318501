<template>
  <v-container fluid grid-list-md>
    <v-layout justify-center>
      <v-flex md8>
        <v-toolbar flat dense
          ><v-toolbar-title style="font-size: 1.8em">
            Financeiro
          </v-toolbar-title>
        </v-toolbar>
        <v-layout row wrap align-center>
          <v-flex v-for="(item, i) in items" :key="i">
            <v-card
              :style="'border: 1px solid LightGrey'"
              :to="item.to"
              class="mx-auto"
              max-width="400"
              max-height="80"
              hover
              v-if="mixPermissao($options.name, item.name)"
            >
              <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                  <v-card-title
                    class="text-h5"
                    v-text="item.title"
                  ></v-card-title>
                  <v-card-subtitle
                    class="subheading grey--text text--darken-1"
                    v-text="item.subtitle"
                  ></v-card-subtitle>
                </div>

                <v-icon class="mr-4" x-large :color="item.icolor">
                  {{ item.icon }}
                </v-icon>
              </div>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import PermissoesMixin from "@/mixins/PermissoesMixin";

export default {
  name: "Financeiro",
  mixins: [PermissoesMixin],
  data: () => ({
    items: [
      {
        to: "/FinPFisicasLista",
        icon: "mdi-account-box",
        icolor: "light-green",
        title: "Pessoas Físicas",
        subtitle: "Cadastro de Pessoas Físicas",
        name: "pfisicas",
      },
      {
        to: "/FinPJuridicasLista",
        icon: "mdi-briefcase-account",
        icolor: "blue",
        title: "Pessoas Jurídicas",
        subtitle: "Cadastro de Pessoas Jurídicas",
        name: "pjuridicas",
      },
    ],
  }),
};
</script>
